import { List, ListButton, Popover } from 'konsta/react'
import { ButtonBase } from '@mui/material'
import { faXmark } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRouter } from 'next/router'
import { useState, useEffect, useRef } from 'react'
import { faHouse } from '@fortawesome/pro-regular-svg-icons'

interface IShopHeaderProps{
    title?: string, // 페이지 타이틀
    hasBackBtn ?: boolean, // 좌측상단 뒤로가기 버튼 노출 여부 
    backBtnFunc?:any, // 뒤로가기 함수
    hasCloseBtn ?: boolean, // 우측 상단 닫기 버튼 노출여부
    closeFunc ?: any, // 닫기 함수,
    bgColor ?:string // header 배경색깔
    className ?:string // styleClass
    noneHome ?: boolean // 홈아이콘 유무
}

const ShopHeader = ({
    title, 
    hasBackBtn, 
    backBtnFunc,
    hasCloseBtn, 
    closeFunc,
    bgColor,
    className,
    noneHome
} : IShopHeaderProps) =>{
    const router = useRouter()

    return(<>
        {router.query.header !== 'N' && (
          <nav className={`fixed top-0 w-full h-12 z-40 flex items-center ${className ?? ""}`} style={{ background: `${bgColor ?? "white"}`}}>
            <div className='font-[500] absolute translate-x-[-50%] left-1/2 whitespace-nowrap'>{title}</div>
            {!hasBackBtn && !hasCloseBtn
            ? <BasicHeader/>
            : <CustomHeader
                noneHome={noneHome}
                hasBackBtn={hasBackBtn}
                backBtnFunc={backBtnFunc}
                hasCloseBtn={hasCloseBtn}
                closeFunc={closeFunc}
                />  
            }
          </nav>
        )}        
    </>)
}

export default ShopHeader

const BasicHeader = () =>{
    const router = useRouter()
    const popoverTargetRef = useRef(null);
    const [openPopOver, setOpenPopOver] = useState<boolean>(false);

    {/** 마이페이지 탭메뉴 오픈 함수 */}
    const openTab = (targetRef:any) =>{
        popoverTargetRef.current = targetRef;
        setOpenPopOver(prev => !prev)
    }

    return (<>
        {/* 내 마켓정보 */}
        <ButtonBase
            className="p-1 rounded-md absolute top-3 left-5 popover-navbar-link"
            onClick={() => openTab('.popover-navbar-link')}>
            <img src="/assets/myshopping.png" className="w-[30px] h-auto"/>
        </ButtonBase>

        <Popover
            className='header-tab'
            opened={openPopOver}
            target={popoverTargetRef.current}
            onBackdropClick={()=>setOpenPopOver(prev=>!prev)}>
            <List>
                <ListButton className="list-btn-text-black" onClick={() => router.push({ pathname : '/shop/mypage/orderList', query : { pageNo : 1 }})}>주문배송조회</ListButton>
                <ListButton className="list-btn-text-black" onClick={() => router.push('/shop/mypage/shippingManage')}>배송지 정보</ListButton>
                <ListButton className="list-btn-text-black" onClick={() => router.push('/shop/mypage/grade')}>회원 등급</ListButton>
                <ListButton className="list-btn-text-black" onClick={() => router.push({ pathname : '/shop/mypage/review', query : { writable : "Y"} })}>후기 관리</ListButton>
                <ListButton className="list-btn-text-black" onClick={() => router.push('/shop/mypage/coupon')}>쿠폰 내역💌</ListButton>
                <ListButton className="list-btn-text-black" onClick={() => router.push('/shop/mypage/inquiry')}>상품문의 내역</ListButton>
            </List>
        </Popover>

        {/* 장바구니 */}
        <ButtonBase
            className="p-1 rounded-md absolute top-3 left-15 popover-navbar-link"
            onClick={() => { router.push('/shop/mypage/cart') } }>
            <img src="/assets/cart.png" className="w-6 h-auto"/>
        </ButtonBase>
    </>)
}

const CustomHeader = ({
    hasBackBtn,
    backBtnFunc,
    hasCloseBtn,
    closeFunc,
    noneHome
} : {
    hasBackBtn ?: boolean, // 좌측상단 뒤로가기 버튼 노출 여부 
    backBtnFunc?:any, // 뒤로가기 함수
    hasCloseBtn ?: boolean, // 우측 상단 닫기 버튼 노출여부
    closeFunc ?: any, // 닫기 함수,
    noneHome ?: boolean
}) => {

    const router = useRouter()

    return (<div>
        {hasBackBtn && (
            <ButtonBase
                className= { "p-1 rounded-md text-[18px] font-medium absolute top-1/2 translate-y-[-50%] left-[10px]" } 
                onClick={backBtnFunc ? backBtnFunc : () => router.back()}>
                <img className='w-6 h-6' src={"/assets/back.png"}/>
            </ButtonBase>
        )}

        {/* {!noneHome && (
            <ButtonBase onClick={()=>router.push({ pathname : "/shop/products" })}>
                <FontAwesomeIcon icon={faHouse} className="absolute top-1/2 translate-y-[-50%] left-11 w-[22px] h-auto"/>
            </ButtonBase>
        )} */}

        {hasCloseBtn && (
            <ButtonBase
                className='absolute top-1/2 translate-y-[-50%] right-[10px]'
                onClick={closeFunc}>
                <FontAwesomeIcon icon={faXmark} className="w-5 h-5"/>
            </ButtonBase>
        )}
    </div>)
}