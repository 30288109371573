import {
    GoodsCategory,
    GoodsPartnerType,
    GoodsSyncTable,
    StoreType,
} from "@prisma/client"

export enum GoodsProdctCodeEnum {
    CALENDAR_2024 = "CALENDAR_2024",
    GSSKHND = "GSSKHND", // 효자손
    KEYRING = "KEYRING",
    ACRYLIC = "ACRYLIC",
}

// 제휴 인쇄업체 코드
export enum PrintingPartnerEnum {
    REDPRINTING = "REDPRINTING",
    PHOTOMON = "PHOTOMON",
    STOPBOOK = "STOPBOOK",
    BLANKER = "BLANKER",
    JUMBO = "JUMBO",
    HUSK = "HUSK",
    FUJI = "FUJI",
    CUSTOMX = "CUSTOMX",
    DEKKANG = "DEKKANG",
}

// 제휴 인쇄업체 한글 텍스트
export enum PrintingPartnerLabelEnum {
    REDPRINTING = "레드프린팅",
    PHOTOMON = "포토몬",
    STOPBOOK = "스탑북",
    BLANKER = "블랭커",
    JUMBO = "점보",
    HUSK = "허스크",
    FUJI = "후지",
    CUSTOMX = "커스텀엑스",
    DEKKANG = "디자인깡패",
}

export const GoodsCategoryLabel: Record<GoodsCategory, string> = {
    CALENDAR: "달력",
    GSSKHND: "효도손",
    KEYRING: "키링",
    ACRYLIC: "아크릴",
}

export const GoodsPartnerLabel: Record<GoodsPartnerType, string> = {
    REDPRINTING: "레드프린팅",
    STOPBOOK: "스탑북",
    PHOTOMON: "포토몬",
    BLANKER: "블랭커",
    JUMBO: "점보",
    HUSK: "허스크",
    FUJI: "후지",
    CUSTOMX: "커스텀엑스",
    DEKKANG: "디자인깡패",
}

export const GoodsSyncTableLabel: Record<GoodsSyncTable, string> = {
    GENGOODS: "웹 달력",
    CUSTOMGOODS: "웹 제작굿즈",
}

export const StoreTypeLabel: Record<StoreType, string> = {
    SHOPBY: "샵바이",
    SMARTSTORE: "스마트스토어",
    SROOKPAY: "스룩페이",
    WADIZ: "와디즈",
    GROUPORDER: "단체주문",
    MANUAL: "수기주문",
    BABYFOLIO: "쑥쑥찰칵",
}
